// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Orbitron', sans-serif;
  background-color: #0d0d0d;
  color: #00ffe4;
  margin: 0;
  padding: 0;
  display: flex;

  align-items: center;
  height: 100vh;
}

.login-container {
  background: linear-gradient(145deg, #1a1a1a, #0f0f0f);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0px 4px 30px rgba(0, 255, 228, 0.3);
  text-align: center;
  width: 300px;
}

.login-title {
  font-size: 2rem;
  color: #ff00f7;
  margin-bottom: 10px;
}

.login-subtitle {
  font-size: 1rem;
  color: #00ffe4;
  margin-bottom: 20px;
}

.login-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 2px solid #00ffe4;
  border-radius: 5px;
  background: transparent;
  color: #00ffe4;
  font-size: 1rem;
}

.login-input:focus {
  outline: none;
  border-color: #ff00f7;
  box-shadow: 0px 0px 5px #ff00f7;
}

.login-button {
  background: #00ffe4;
  color: #0d0d0d;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.login-button:hover {
  background: #ff00f7;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/LoginForm.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,yBAAyB;EACzB,cAAc;EACd,SAAS;EACT,UAAU;EACV,aAAa;;EAEb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,qDAAqD;EACrD,aAAa;EACb,mBAAmB;EACnB,+CAA+C;EAC/C,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,uBAAuB;EACvB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb","sourcesContent":["body {\n  font-family: 'Orbitron', sans-serif;\n  background-color: #0d0d0d;\n  color: #00ffe4;\n  margin: 0;\n  padding: 0;\n  display: flex;\n\n  align-items: center;\n  height: 100vh;\n}\n\n.login-container {\n  background: linear-gradient(145deg, #1a1a1a, #0f0f0f);\n  padding: 40px;\n  border-radius: 15px;\n  box-shadow: 0px 4px 30px rgba(0, 255, 228, 0.3);\n  text-align: center;\n  width: 300px;\n}\n\n.login-title {\n  font-size: 2rem;\n  color: #ff00f7;\n  margin-bottom: 10px;\n}\n\n.login-subtitle {\n  font-size: 1rem;\n  color: #00ffe4;\n  margin-bottom: 20px;\n}\n\n.login-input {\n  width: 100%;\n  padding: 10px;\n  margin-bottom: 15px;\n  border: 2px solid #00ffe4;\n  border-radius: 5px;\n  background: transparent;\n  color: #00ffe4;\n  font-size: 1rem;\n}\n\n.login-input:focus {\n  outline: none;\n  border-color: #ff00f7;\n  box-shadow: 0px 0px 5px #ff00f7;\n}\n\n.login-button {\n  background: #00ffe4;\n  color: #0d0d0d;\n  border: none;\n  padding: 10px 20px;\n  font-size: 1rem;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: all 0.3s ease;\n  width: 100%;\n}\n\n.login-button:hover {\n  background: #ff00f7;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
